<template>
  <div>
    <div v-if="isLoadingExport" class="loading-overlay">
      <img src="https://media.tenor.com/lgHf3NScHasAAAAi/loadinggif.gif" alt="Loading..." class="loading-gif" />
    </div>
<!-- Modal Attachments-->
<b-modal
      id="modal-attachments"
      cancel-variant="outline-secondary"
      v-model="modalFileAttachments"
      hide-footer
      centered
      title="Download Attachments"
    >
      <b-form>

    <div v-if="fileAttachments">
    <label for="file-list">File List : </label>
    <ul class="list-group">
        <li  class="list-group-item" v-for="item in fileAttachments" :key="item.InvoiceAttachmentID">
              <a target="_blank" @click="downloadAttachment(item.InvoiceAttachment)">{{ item.InvoiceAttachment }}</a> <a style="color:red;" class="float-right" @click="deleteAttachment(item.InvoiceAttachmentID)"><feather-icon icon="TrashIcon" /></a>
        </li>
    </ul>
    </div>
      </b-form>
    </b-modal>
    <!-- Modal Edit-->
    <b-modal
      id="modal-edit"
      v-model="modalShow"
      centered
      hide-footer
      title="Edit Form"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="editInvoiceProcess"
            >
        <b-form-group>
          <label for="email">Invoice No:</label>
          <input type="text" name="InvoiceNo" class="form-control" v-model="InvoiceNumberOld" readonly>

        </b-form-group>
        <b-form-group>
          <label for="email">Date Pusat:</label>
          <input type="hidden" name="InvoiceID" class="form-control" v-model="InvoiceID">
        <input
          id="invoice-date"
          type="date"
          v-model="InvoiceDate"
          container="body"
        />
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">No Invoice Pusat</label>
          <b-form-input
            type="text"
            placeholder="No Invoice"
          v-model="InvoiceNumber"
          />
        </b-form-group>
        <div class="pull-right">
          <b-btn @click="modalShow = false" class="btn btn-outline-secondary">Cancel</b-btn> &nbsp;
          <b-btn variant="outline-primary"
                type="button" @click="editInvoiceProcess();modalShow=false" class="btn btn-primary">Save</b-btn>
        </div>
      </b-form>
    </b-modal>
        <!-- Modal Upload-->
        <b-modal
      id="modal-upload"
      cancel-variant="outline-secondary"
      v-model="modalUploadShow"
      hide-footer
      centered
      title="Upload Form"
    >

      <b-form>
        <b-form-group>
          <label for="email">File:</label>
          <b-form-file
            placeholder="Choose one or more files or drop it here..."
            drop-placeholder="Drop files here..."
            type="file" id="file" ref="uploadFile" v-on:change="handleFilesUpload"
          />
          <input type="hidden" name="uploadInvoiceId" id="uploadInvoiceId" v-model="uploadInvoiceId">
        </b-form-group>

        <div class="pull-right">
          <b-btn @click="modalUploadShow = false" class="btn btn-outline-secondary">Cancel</b-btn> &nbsp;
          <b-btn variant="outline-primary"
                type="button" @click="uploadAttachment();modalUploadShow=false;" class="btn btn-primary">Upload</b-btn>
        </div>
      </b-form>
    </b-modal>
    <!-- Modal Attachments-->
    <b-modal
      id="modal-attachments"
      v-model="attachmentModal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Contract"
    >
        <div>
          <div class="messages">
            <b-form-group label="Agreement Reference *" label-for="agreementRef">
              <b-form-input v-model="agreementRef" placeholder="Agreement Reference" readonly />
            </b-form-group>
             <!-- Customer -->
          <b-form-group label="Customer *" label-for="customer">
            <b-form-input v-model="customer" placeholder="Customer" readonly />
          </b-form-group>

          <!-- Description -->
          <b-form-group label="Description *" label-for="description">
            <b-form-input v-model="description" placeholder="Description" readonly />
          </b-form-group>

          <!-- Start Date -->
          <b-form-group label="Start Date *" label-for="startDate">
            <b-form-input v-model="startDate_" placeholder="Start Date" readonly />
          </b-form-group>

          <!-- End Date -->
          <b-form-group label="End Date *" label-for="endDate">
            <b-form-input v-model="endDate_" placeholder="End Date" readonly />
          </b-form-group>

          <b-form-group label="Attachments *" label-for="fileAttachments">
            <ul
              :ref="fileAttachments"
              v-if="fileAttachments.length > 0"
              class="list-group"
            >
              <li
                v-for="item in fileAttachments"
                :key="item"
                class="list-group-item"
              >
              <div class="float-left">
                <a href="#" @click.prevent="downloadFile(item.relative_path, item.filename)">{{ item.filename }}</a>
      </div>
              </li>
            </ul>
            </b-form-group>
            <div v-if="fileAttachments.length == 0">
              No Data
            </div><!-- File Upload Section -->
          </div>
        </div>
    </b-modal>
        <!-- Modal Messages-->
        <b-modal
      id="modal-messages"
      v-model="messageModal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Messages"
    >
      <b-form>
    <div>
        <div class="messages">
        <ul class="list-group" style="height:300px;width:100%;overflow-y:scroll;" v-if="messagesList.length>0">
          <input type="hidden" name="invoiceMessageId" id="invoiceMessageId" ref="invoiceMessage" v-model="invoiceMessageId">
            <li  class="list-group-item" v-for="item in messagesList" :key="item">
              <div class="float-right" v-if="userFullName.toString()==item.FullName.toString()">
              <h5>Me</h5>
              <span style="font-size:9px">{{dateFormat(item.InputTime)}}</span>
              <p>{{item.invoiceMessage}}</p>
              </div>
              <div class="float-left" v-if="userFullName.toString()!=item.FullName.toString()">
              <h5>{{item.FullName}}</h5>
              <span style="font-size:9px">{{dateFormat(item.InputTime)}}</span>
              <p>{{item.invoiceMessage}}</p>
              </div>
            </li>
        </ul>
    <div v-if="messagesList.length==0">
      No message
      </div><br>
          <form method="post" action="" id="send_message_form">
            <b-row>
            <b-col
          cols="11"
          md="9"
          >
            <input type="text" class="form-control" v-model="messageBox" placeholder="Write your message">
            </b-col>
            <b-col
          cols="11"
          md="3"><input type="button" class="btn btn-primary pull-right" @click="sendMessage()" value="Send"></b-col>
          </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- Modal Edit-->
    <b-modal
      id="modal-edit"
      v-model="modalShow"
      centered
      hide-footer
      title="Edit Form"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="editInvoiceProcess"
            >
        <b-form-group>
          <label for="email">Invoice No:</label>
          <input type="text" name="InvoiceNo" class="form-control" v-model="InvoiceNumberOld" readonly>

        </b-form-group>
        <b-form-group>
          <label for="email">Date Pusat:</label>
          <input type="hidden" name="InvoiceID" class="form-control" v-model="InvoiceID">
        <b-form-datepicker
          id="invoice-date"
          v-model="InvoiceDate"
        />
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">No Invoice Pusat</label>
          <b-form-input
            type="text"
            placeholder="No Invoice"
          v-model="InvoiceNumber"
          />
        </b-form-group>
        <div class="pull-right">
          <b-btn @click="modalShow = false" class="btn btn-outline-secondary">Cancel</b-btn> &nbsp;
          <b-btn variant="outline-primary"
                type="button" @click="editInvoiceProcess();modalShow=false" class="btn btn-primary">Save</b-btn>
        </div>
      </b-form>
    </b-modal>
    <b-card no-body class="mb-0">
      <b-tabs>
        <b-tab title="All">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker" v-model="startDateFilter" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker" v-model="endDateFilter" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <button @click="exportData('all', 'csvExportAll')" class="btn btn-success float-right">Export CSV</button>
              <vue-json-to-csv
                class = "btn btn-success float-right hidden"
                :csv-title="'invoice_all_internal'"
                :json-data = "invoicesAll"
                :labels = "labels"
                ref="csvExportAll"
                >
                Download CSV
              </vue-json-to-csv>
              </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2 pad-down-2"
          v-if="userRole!=='branch'"
        >
          <label>Branch</label><br>
          <v-select
                v-model="branchFilterAll"
                :dir="'ltr'"
                :options="branches"
                :reduce="val => val.value"
                :clearable="false"
                input-id="branchId"
              />
    </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="fixed-height-table">
            <b-table
              ref="refInvoiceListTable"
              class="position-relative btable"
              :items="fetchInvoices"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
            >
              <!-- Column: Number -->
              <template #cell(no)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ (currentPage - 1) * perPage + data.index + 1 }}</span> <!-- Adding 1 to make it 1-based -->
        </div>
      </template>
              <!-- Column: Customer -->
              <template #cell(customer)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.Customer
                  }}</span>
                </div>
              </template>
              <!-- Column: job_no -->
              <template #cell(job_no)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.JobNo
                  }}</span>
                </div>
              </template>
              <!-- Column: inv_no -->
              <template #cell(inv_no)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.InvoiceNumber
                  }}</span>
                </div>
              </template>
              <!-- Column: inv_date -->
              <template #cell(inv_date)="data">
                {{
                  dateSimple(
                    data.item.InvoiceDate,
                    "YYYY-MM-DD"
                  )
                }}
              </template>
              <!-- Column: no_inv_pusat -->
              <template #cell(no_inv_pusat)="data">
                <b-badge pill class="text-capitalize">
                  {{ data.item.InvoicePusat ? data.item.InvoicePusat: "-" }}
                </b-badge>
              </template>
              <template #cell(supporting_doc)="data">
                <b-badge pill class="text-capitalize">
                  {{ data.item.SupportingDoc }}
                </b-badge>
              </template>
              <template #cell(no_inv_pusat_date)="data">
                <b-badge pill class="text-capitalize">
                  {{ data.item.InvoicePusatDate!="-" ? dateSimple(
                    data.item.InvoicePusatDate,
                    "YYYY-MM-DD"
                  ): "-" }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
        <b-dropdown-item v-if="true" @click="showDetail( data.item.InvoiceNumber )">
          <feather-icon icon="SearchIcon" />
          <span class="align-middle ml-50">Detail</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="data.item.DownloadInvoice!=''" @click="downloadInvoice( data.item.InvoiceID )">
          <feather-icon icon="DownloadIcon" />
          <span class="align-middle ml-50">Download Invoice</span>
        </b-dropdown-item>

        <b-dropdown-item  @click="showAttachments( data.item.InvoiceID )" >
          <feather-icon icon="FileIcon" />
          <span class="align-middle ml-50">Show Attachments</span>
        </b-dropdown-item>

        <b-dropdown-item @click="uploadFiles( data.item.InvoiceID )">
          <feather-icon icon="UploadIcon" />
          <span class="align-middle ml-50">Upload</span>
        </b-dropdown-item>
                <b-dropdown-item v-if="userRole==='admin' || userRole==='finance' || userRole==='super-admin'" @click="editInvoice( data.item.InvoiceID, dateSimple(data.item.InvoicePusatDate), data.item.InvoicePusat, dateSimple(data.item.InvoiceDate), data.item.InvoiceNumber )">
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="data.item.invoiceApproved == 'Not Approved'&& (userRole==='finance-supervisor' || userRole==='admin'  || userRole==='super-admin')" @click="confirmInvoice( data.item.InvoiceID )">
          <feather-icon icon="CheckIcon" />
          <span class="align-middle ml-50">Confirm</span>
        </b-dropdown-item>
        <b-dropdown-item @click="showMessages( data.item.InvoiceID )">
          <feather-icon icon="MessageCircleIcon" />
          <span class="align-middle ml-50">Messages</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="userRole==='admin' || userRole==='super-admin'" @click="voidInvoice( data.item.InvoiceID )">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Void</span>
        </b-dropdown-item>
        <b-dropdown-item @click="showContractAttachments( data.item.Agreement )">
          <feather-icon icon="FileIcon" />
          <span class="align-middle ml-50">Contract</span>
        </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.from }} to
                   {{ dataMeta.to }} of
                  {{ dataMeta.of }} entries</span
                >
                <span style="padding-left: 10px;">
                  <feather-icon
                  style="color:#00FF00"
                  icon="CheckCircleIcon"
                /> = Accepted,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Confirmed">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-finished" v-model="startDateFilterConfirmed" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-finished" v-model="endDateFilterConfirmed" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <button @click="exportData('confirmed', 'csvExportConfirmed')" class="btn btn-success float-right">Export CSV</button>
              <vue-json-to-csv
                class = "btn btn-success float-right hidden"
                :csv-title="'invoice_confirmed_internal'"
                :json-data = "invoicesAll"
                :labels = "labels"
                ref="csvExportConfirmed"
                >
                Download CSV
              </vue-json-to-csv>
              </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2 pad-down-2"
          v-if="userRole!=='branch'"
        >
          <label>Branch</label><br>
          <v-select
                v-model="branchFilterConfirmed"
                :dir="'ltr'"
                :options="branches"
                :reduce="val => val.value"
                :clearable="false"
                input-id="branchId"
              />
    </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageConfirmed"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryConfirmed"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="fixed-height-table">
            <b-table
              ref="refInvoiceListTableConfirmed"
              class="position-relative btable"
              :items="fetchInvoicesConfirmed"
              responsive
              :fields="tableColumnsConfirmed"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
            >
              <!-- Column: Number -->
              <template #cell(no)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ (currentPage - 1) * perPage + data.index + 1 }}</span> <!-- Adding 1 to make it 1-based -->
        </div>
      </template>
              <!-- Column: Customer -->
              <template #cell(customer)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.Customer
                  }}</span>
                </div>
              </template>
              <!-- Column: job_no -->
              <template #cell(job_no)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.JobNo
                  }}</span>
                </div>
              </template>
              <!-- Column: inv_no -->
              <template #cell(inv_no)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.InvoiceNumber
                  }}</span>
                </div>
              </template>
              <!-- Column: inv_date -->
              <template #cell(inv_date)="data">
                {{
                  dateSimple(
                    data.item.InvoiceDate,
                    "YYYY-MM-DD"
                  )
                }}
              </template>
              <!-- Column: no_inv_pusat -->
              <template #cell(no_inv_pusat)="data">
                <b-badge pill class="text-capitalize">
                  {{ data.item.InvoicePusat ? data.item.InvoicePusat: "-" }}
                </b-badge>
              </template>
              <template #cell(supporting_doc)="data">
                <b-badge pill class="text-capitalize">
                  {{ data.item.SupportingDoc }}
                </b-badge>
              </template>
              <template #cell(no_inv_pusat_date)="data">
                <b-badge pill class="text-capitalize">
                  {{ data.item.InvoicePusatDate!="-" ? dateSimple(
                    data.item.InvoicePusatDate,
                    "YYYY-MM-DD"
                  ): "-" }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
        <b-dropdown-item v-if="true" @click="showDetail( data.item.InvoiceNumber )">
          <feather-icon icon="SearchIcon" />
          <span class="align-middle ml-50">Detail</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="data.item.DownloadInvoice!=''" @click="downloadInvoice( data.item.InvoiceID )">
          <feather-icon icon="DownloadIcon" />
          <span class="align-middle ml-50">Download Invoice</span>
        </b-dropdown-item>

        <b-dropdown-item  @click="showAttachments( data.item.InvoiceID )" >
          <feather-icon icon="FileIcon" />
          <span class="align-middle ml-50">Show Attachments</span>
        </b-dropdown-item>

        <b-dropdown-item @click="uploadFiles( data.item.InvoiceID )">
          <feather-icon icon="UploadIcon" />
          <span class="align-middle ml-50">Upload</span>
        </b-dropdown-item>
                <b-dropdown-item v-if="userRole==='admin' || userRole==='finance' || userRole==='super-admin'" @click="editInvoice( data.item.InvoiceID, dateSimple(data.item.InvoicePusatDate), data.item.InvoicePusat, dateSimple(data.item.InvoiceDate), data.item.InvoiceNumber )">
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="data.item.invoiceApproved == 'Not Approved'&& (userRole==='finance-supervisor' || userRole==='admin'  || userRole==='super-admin')" @click="confirmInvoice( data.item.InvoiceID )">
          <feather-icon icon="CheckIcon" />
          <span class="align-middle ml-50">Confirm</span>
        </b-dropdown-item>
        <b-dropdown-item @click="showMessages( data.item.InvoiceID )">
          <feather-icon icon="MessageCircleIcon" />
          <span class="align-middle ml-50">Messages</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="userRole==='admin' || userRole==='super-admin'" @click="voidInvoice( data.item.InvoiceID )">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Void</span>
        </b-dropdown-item>
        <b-dropdown-item @click="showContractAttachments( data.item.Agreement )">
          <feather-icon icon="FileIcon" />
          <span class="align-middle ml-50">Contract</span>
        </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaConfirmed.from }} to
                  {{ dataMetaConfirmed.to }} of
                  {{ dataMetaConfirmed.of }} entries</span
                >
                <span style="padding-left: 10px;">
                  <feather-icon
                  style="color:#00FF00"
                  icon="CheckCircleIcon"
                /> = Accepted,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageConfirmed"
                  :total-rows="totalItemsConfirmed"
                  :per-page="perPageConfirmed"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Unconfirmed">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-Unconfirmed" v-model="startDateFilterUnconfirmed" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-Unconfirmed" v-model="endDateFilterUnconfirmed" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <button @click="exportData('unconfirmed', 'csvExportUnconfirmed')" class="btn btn-success float-right">Export CSV</button>
              <vue-json-to-csv
                class = "btn btn-success float-right hidden"
                :csv-title="'invoice_unconfirmed_internal'"
                :json-data = "invoicesAll"
                :labels = "labels"
                ref="csvExportUnconfirmed"
                >
                Download CSV
              </vue-json-to-csv>
              </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2 pad-down-2"
          v-if="userRole!=='branch'"
        >
          <label>Branch</label><br>
          <v-select
                v-model="branchFilterUnconfirmed"
                :dir="'ltr'"
                :options="branches"
                :reduce="val => val.value"
                :clearable="false"
                input-id="branchId"
              />
    </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageUnconfirmed"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>
              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryUnconfirmed"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="fixed-height-table">
            <b-table
              ref="refInvoiceListTableUnconfirmed"
              class="position-relative btable"
              :items="fetchInvoicesUnconfirmed"
              responsive
              :fields="tableColumnsUnconfirmed"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
            >
            <!-- Column: Number -->
            <template #cell(no)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ (currentPage - 1) * perPage + data.index + 1 }}</span> <!-- Adding 1 to make it 1-based -->
        </div>
      </template>
              <!-- Column: Customer -->
              <template #cell(customer)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.Customer
                  }}</span>
                </div>
              </template>
              <!-- Column: job_no -->
              <template #cell(job_no)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.JobNo
                  }}</span>
                </div>
              </template>
              <!-- Column: inv_no -->
              <template #cell(inv_no)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.InvoiceNumber
                  }}</span>
                </div>
              </template>
              <!-- Column: inv_date -->
              <template #cell(inv_date)="data">
                {{
                  dateSimple(
                    data.item.InvoiceDate,
                    "YYYY-MM-DD"
                  )
                }}
              </template>
              <!-- Column: no_inv_pusat -->
              <template #cell(no_inv_pusat)="data">
                <b-badge pill class="text-capitalize">
                  {{ data.item.InvoicePusat ? data.item.InvoicePusat: "-" }}
                </b-badge>
              </template>
              <template #cell(supporting_doc)="data">
                <b-badge pill class="text-capitalize">
                  {{ data.item.SupportingDoc }}
                </b-badge>
              </template>
              <template #cell(no_inv_pusat_date)="data">
                <b-badge pill class="text-capitalize">
                  {{ data.item.InvoicePusatDate!="-" ? dateSimple(
                    data.item.InvoicePusatDate,
                    "YYYY-MM-DD"
                  ): "-" }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
        <b-dropdown-item v-if="true" @click="showDetail( data.item.InvoiceNumber )">
          <feather-icon icon="SearchIcon" />
          <span class="align-middle ml-50">Detail</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="data.item.DownloadInvoice!=''" @click="downloadInvoice( data.item.InvoiceID )">
          <feather-icon icon="DownloadIcon" />
          <span class="align-middle ml-50">Download Invoice</span>
        </b-dropdown-item>

        <b-dropdown-item  @click="showAttachments( data.item.InvoiceID )" >
          <feather-icon icon="FileIcon" />
          <span class="align-middle ml-50">Show Attachments</span>
        </b-dropdown-item>

        <b-dropdown-item @click="uploadFiles( data.item.InvoiceID )">
          <feather-icon icon="UploadIcon" />
          <span class="align-middle ml-50">Upload</span>
        </b-dropdown-item>
                <b-dropdown-item v-if="userRole==='admin' || userRole==='finance' || userRole==='super-admin'" @click="editInvoice( data.item.InvoiceID, dateSimple(data.item.InvoicePusatDate), data.item.InvoicePusat, dateSimple(data.item.InvoiceDate), data.item.InvoiceNumber )">
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="data.item.invoiceApproved == 'Not Approved'&& (userRole==='finance-supervisor' || userRole==='admin'  || userRole==='super-admin')" @click="confirmInvoice( data.item.InvoiceID )">
          <feather-icon icon="CheckIcon" />
          <span class="align-middle ml-50">Confirm</span>
        </b-dropdown-item>
        <b-dropdown-item @click="showMessages( data.item.InvoiceID )">
          <feather-icon icon="MessageCircleIcon" />
          <span class="align-middle ml-50">Messages</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="userRole==='admin' || userRole==='super-admin'" @click="voidInvoice( data.item.InvoiceID )">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Void</span>
        </b-dropdown-item>
        <b-dropdown-item @click="showContractAttachments( data.item.Agreement )">
          <feather-icon icon="FileIcon" />
          <span class="align-middle ml-50">Contract</span>
        </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaUnconfirmed.from }} to
                  {{ dataMetaUnconfirmed.to }} of
                  {{ dataMetaUnconfirmed.of }} entries</span
                >
                <span style="padding-left: 10px;">
                  <feather-icon
                  style="color:#00FF00"
                  icon="CheckCircleIcon"
                /> = Accepted,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageUnconfirmed"
                  :total-rows="totalItemsUnconfirmed"
                  :per-page="perPageUnconfirmed"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Empty">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-void" v-model="startDateFilterEmpty" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-void" v-model="endDateFilterEmpty" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <button @click="exportData('empty', 'csvExportEmpty')" class="btn btn-success float-right">Export CSV</button>
              <vue-json-to-csv
                class = "btn btn-success float-right hidden"
                :csv-title="'invoice_empty_internal'"
                :json-data = "invoicesAll"
                :labels = "labels"
                ref="csvExportEmpty"
                >
                Download CSV
              </vue-json-to-csv>
              </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2 pad-down-2"
          v-if="userRole!=='branch'"
        >
          <label>Branch</label><br>
          <v-select
                v-model="branchFilterEmpty"
                :dir="'ltr'"
                :options="branches"
                :reduce="val => val.value"
                :clearable="false"
                input-id="branchId"
              />
    </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageEmpty"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryEmpty"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="fixed-height-table">
            <b-table
              ref="refInvoiceListTableEmpty"
              class="position-relative btable"
              :items="fetchInvoicesEmpty"
              responsive
              :fields="tableColumnsEmpty"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
            >
              <!-- Column: Number -->
              <!-- Column: Number -->
              <template #cell(no)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ (currentPage - 1) * perPage + data.index + 1 }}</span> <!-- Adding 1 to make it 1-based -->
        </div>
      </template>
              <!-- Column: Customer -->
              <template #cell(customer)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.Customer
                  }}</span>
                </div>
              </template>
              <!-- Column: job_no -->
              <template #cell(job_no)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.JobNo
                  }}</span>
                </div>
              </template>
              <!-- Column: inv_no -->
              <template #cell(inv_no)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.InvoiceNumber
                  }}</span>
                </div>
              </template>
              <!-- Column: inv_date -->
              <template #cell(inv_date)="data">
                {{
                  dateSimple(
                    data.item.InvoiceDate,
                    "YYYY-MM-DD"
                  )
                }}
              </template>
              <!-- Column: no_inv_pusat -->
              <template #cell(no_inv_pusat)="data">
                <b-badge pill class="text-capitalize">
                  {{ data.item.InvoicePusat ? data.item.InvoicePusat: "-" }}
                </b-badge>
              </template>
              <template #cell(supporting_doc)="data">
                <b-badge pill class="text-capitalize">
                  {{ data.item.SupportingDoc }}
                </b-badge>
              </template>
              <template #cell(no_inv_pusat_date)="data">
                <b-badge pill class="text-capitalize">
                  {{ data.item.InvoicePusatDate!="-" ? dateSimple(
                    data.item.InvoicePusatDate,
                    "YYYY-MM-DD"
                  ) : "-" }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
        <b-dropdown-item v-if="true" @click="showDetail( data.item.InvoiceNumber )">
          <feather-icon icon="SearchIcon" />
          <span class="align-middle ml-50">Detail</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="data.item.DownloadInvoice!=''" @click="downloadInvoice( data.item.InvoiceID )">
          <feather-icon icon="DownloadIcon" />
          <span class="align-middle ml-50">Download Invoice</span>
        </b-dropdown-item>

        <b-dropdown-item  @click="showAttachments( data.item.InvoiceID )" >
          <feather-icon icon="FileIcon" />
          <span class="align-middle ml-50">Show Attachments</span>
        </b-dropdown-item>

        <b-dropdown-item @click="uploadFiles( data.item.InvoiceID )">
          <feather-icon icon="UploadIcon" />
          <span class="align-middle ml-50">Upload</span>
        </b-dropdown-item>
                <b-dropdown-item v-if="userRole==='admin' || userRole==='finance' || userRole==='super-admin'" @click="editInvoice( data.item.InvoiceID, dateSimple(data.item.InvoicePusatDate), data.item.InvoicePusat, dateSimple(data.item.InvoiceDate), data.item.InvoiceNumber )">
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="data.item.invoiceApproved == 'Not Approved'&& (userRole==='finance-supervisor' || userRole==='admin'  || userRole==='super-admin')" @click="confirmInvoice( data.item.InvoiceID )">
          <feather-icon icon="CheckIcon" />
          <span class="align-middle ml-50">Confirm</span>
        </b-dropdown-item>
        <b-dropdown-item @click="showMessages( data.item.InvoiceID )">
          <feather-icon icon="MessageCircleIcon" />
          <span class="align-middle ml-50">Messages</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="userRole==='admin' || userRole==='super-admin'" @click="voidInvoice( data.item.InvoiceID )">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Void</span>
        </b-dropdown-item>
        <b-dropdown-item @click="showContractAttachments( data.item.Agreement )">
          <feather-icon icon="FileIcon" />
          <span class="align-middle ml-50">Contract</span>
        </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaEmpty.from }} to
                  {{ dataMetaEmpty.to }} of
                  {{ dataMetaEmpty.of }} entries</span
                >
                <span style="padding-left: 10px;">
                  <feather-icon
                  style="color:#00FF00"
                  icon="CheckCircleIcon"
                /> = Accepted,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageEmpty"
                  :total-rows="totalItemsEmpty"
                  :per-page="perPageEmpty"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTabs,
  BTab,
  BFormDatepicker,
  BFormFile,
} from "bootstrap-vue"
import axios from "axios"
import vSelect from "vue-select"
import VueJsonToCsv from 'vue-json-to-csv'
import store from "@/store"
import moment from "moment"
import { getToken, getUserData } from '@/auth/utils'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ref, onUnmounted } from "@vue/composition-api"
import { avatarText } from "@core/utils/filter"
import InvoiceList from "./List"

// Unchanged imports
const messageBox = ""
const to = ""
const cc = ""
const reason = ""
const materialRequestId = ""
const messagesList = []
const statusItems = []
const items = []
const fileAttachments = []
const userId = getUserData().user_id
const userRole = ""
const file = ''
const uploadInvoiceId = ''
const modalDetailShow = false
const modalMessage = false
const userFullName = getUserData().FullName
const modalUpload = false
const documentsList = []
const branches = []

export default {
  components: {
    VueJsonToCsv,
    BFormGroup,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormDatepicker,
    BFormFile,
    vSelect,
  },
  data() {
    return {
      customer: '',
      agreementRef: '',
      description: '',
      branches,
      userFullName,
      modalShow: false,
      invoicesAll: [],
      invoicesConfirmed: [],
      invoicesUnconfirmed: [],
      invoicesEmpty: [],
      InvoiceID: '',
      InvoiceDate: '',
      InvoiceNumber: '',
      InvoiceNumberOld: '',
      startDate_: '',
      endDate_: '',
      modalFileAttachments: false,
      currentCode: '',
      modalUploadShow: false,
      messageModal: false,
      uploadInvoiceId,
      fileAttachments,
      attachmentModal: false,
      file,
      modalUpload,
      items,
      statusItems,
      materialRequestId,
      userId,
      messagesList,
      documentsList,
      isLoadingExport: false,
      isLoadingExportConfirmed: false,
      isLoadingExportUnconfirmed: false,
      labels: {
        Customer: { title: 'Customer' },
        JobNo: { title: 'Job No' },
        InvoiceNumber: { title: 'Inv No' },
        InvoiceDate: { title: 'Inv Date' },
        Reference: { title: 'Inv Date' },
        Currency: { title: 'Currency' },
        DPP: { title: 'DPP' },
        Tax: { title: 'Tax' },
        GrandTotal: { title: 'GrandTotal' },
        InvoicePusat: { title: 'No Inv Pusat' },
        InvoicePusatDate: { title: 'Inv Pusat Date' },
        invoiceApproved: { title: 'Confirmed' },
        SupportingDoc: { title: 'Supporting Doc' },
},
    }
  },
  mounted() {
    this.userRole = getUserData().UserRoleSystem
    this.getBranches()
    this.$root.$on("refreshTable", text => {
      console.log(text)
      this.$refs.refInvoiceListTable.refresh()
      this.$refs.refInvoiceListTableConfirmed.refresh()
      this.$refs.refInvoiceListTableUnconfirmed.refresh()
    })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = "app-material-request"
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      }
    })

    const isAddNewInvoiceSidebarActive = ref(false)

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ]

    const {
      fetchInvoices,
      fetchInvoicesConfirmed,
      fetchInvoicesUnconfirmed,
      fetchInvoicesEmpty,
      tableColumns,
      tableColumnsConfirmed,
      tableColumnsUnconfirmed,
      tableColumnsEmpty,
      perPage,
      currentPage,
      perPageConfirmed,
      currentPageConfirmed,
      dataMetaConfirmed,
      searchQueryConfirmed,
      perPageUnconfirmed,
      currentPageUnconfirmed,
      dataMetaUnconfirmed,
      searchQueryUnconfirmed,
      perPageEmpty,
      currentPageEmpty,
      dataMetaEmpty,
      searchQueryEmpty,
      totalItems,
      totalItemsConfirmed,
      totalItemsUnconfirmed,
      totalItemsEmpty,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      refInvoiceListTableConfirmed,
      refInvoiceListTableUnconfirmed,
      refInvoiceListTableEmpty,
      refetchData,
      refetchDataConfirmed,
      refetchDataEmpty,
      resolveInvoiceRoleVariant,
      resolveInvoiceRoleIcon,
      resolveInvoiceStatusVariant,
      roleOptions,
      roleFilter,
      statusFilter,
      startDateFilter,
      endDateFilter,
      startDateFilterConfirmed,
      endDateFilterConfirmed,
      startDateFilterUnconfirmed,
      endDateFilterUnconfirmed,
      startDateFilterEmpty,
      endDateFilterEmpty,
      branchFilterAll,
      branchFilterConfirmed,
      branchFilterUnconfirmed,
      branchFilterEmpty,
    } = InvoiceList()

    return {
      branchFilterAll,
      branchFilterConfirmed,
      branchFilterUnconfirmed,
      branchFilterEmpty,
      statusOptions,
      to,
      cc,
      reason,
      messageBox,
      modalDetailShow,
      modalMessage,
      isAddNewInvoiceSidebarActive,
      userRole,
      fetchInvoices,
      fetchInvoicesConfirmed,
      fetchInvoicesUnconfirmed,
      fetchInvoicesEmpty,
      tableColumns,
      tableColumnsConfirmed,
      tableColumnsUnconfirmed,
      tableColumnsEmpty,
      perPage,
      currentPage,
      totalItems,
      perPageConfirmed,
      currentPageConfirmed,
      dataMetaConfirmed,
      searchQueryConfirmed,
      totalItemsConfirmed,
      perPageUnconfirmed,
      currentPageUnconfirmed,
      dataMetaUnconfirmed,
      searchQueryUnconfirmed,
      totalItemsUnconfirmed,
      totalItemsEmpty,
      perPageEmpty,
      currentPageEmpty,
      dataMetaEmpty,
      searchQueryEmpty,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      refInvoiceListTableConfirmed,
      refInvoiceListTableEmpty,
      refInvoiceListTableUnconfirmed,
      refetchData,
      refetchDataConfirmed,
      refetchDataEmpty,
      avatarText,
      resolveInvoiceRoleVariant,
      resolveInvoiceRoleIcon,
      resolveInvoiceStatusVariant,
      roleOptions,
      roleFilter,
      statusFilter,
      startDateFilter,
      endDateFilter,
      startDateFilterConfirmed,
      endDateFilterConfirmed,
      startDateFilterUnconfirmed,
      endDateFilterUnconfirmed,
      startDateFilterEmpty,
      endDateFilterEmpty,
    }
  },
  methods: {
      getBranches() {
      const userToken = getToken()
        const header = {
          'Content-Type': 'application/json',
          'X-Token-Access': `Bearer ${userToken}`,
        }
          axios
              .get(process.env.VUE_APP_API_GET_BRANCH, { headers: header })
              .then(response => {
                const branchesJoin = response.data.Payload.map(elem => {
                  return { label: `${elem.Deskripsi} (${elem.KodeUnitKerja})`, value: elem.KodeUnitKerja }
                })
                this.branches = branchesJoin.sort((a, b) => parseInt(a.value) > parseInt(b.value) ? 1 : -1)
              })
              .catch(err => {
                  console.log(err)
              })
      },
    async voidInvoice(invoiceId) {
      const userToken = getToken()
        const header = {
          'Content-Type': 'application/json',
          'X-Token-Access': `Bearer ${userToken}`,
        }
      const body = {
        InvoiceID: invoiceId,
      }
    axios
      .post('https://api.geolims.com/invoice_service/voidinvoice', body, { headers: header })
      .then(async response => {
        if (response.data.Status === 1) {
          this.$refs.refInvoiceListTable.refresh()
          this.$refs.refInvoiceListTableConfirmed.refresh()
          this.$refs.refInvoiceListTableUnconfirmed.refresh()
          this.$refs.refInvoiceListTableEmpty.refresh()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Void invoice success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    async confirmInvoice(invoiceID) {
      const userToken = getToken()
        const header = {
          'Content-Type': 'application/json',
          'X-Token-Access': `Bearer ${userToken}`,
        }
      const body = {
        InvoiceID: invoiceID,
      }
    axios
      .post('https://api.geolims.com/invoice_service/ApproveInvoice', body, { headers: header })
      .then(async response => {
        if (response.data.Status === 1) {
          this.$refs.refInvoiceListTable.refresh()
          this.$refs.refInvoiceListTableConfirmed.refresh()
          this.$refs.refInvoiceListTableUnconfirmed.refresh()
          this.$refs.refInvoiceListTableEmpty.refresh()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Invoice success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
     },
    editInvoice(invoiceID, invoiceDatePusat, invoiceNumberPusat, invoiceDate, invoiceNumber) {
      this.modalShow = true
      this.InvoiceID = invoiceID
      this.InvoiceDate = invoiceDatePusat !== null ? invoiceDatePusat : invoiceDate
      this.InvoiceNumber = invoiceNumberPusat !== null ? invoiceNumberPusat : ''
      this.InvoiceNumberOld = invoiceNumber
    },
    async editInvoiceProcess() {
      const userToken = getToken()
        const header = {
          'Content-Type': 'application/json',
          'X-Token-Access': `Bearer ${userToken}`,
        }
      const body = {
        InvoiceID: this.InvoiceID,
        Date: this.InvoiceDate,
        NoInvoice: this.InvoiceNumber,
      }
    axios
      .post('https://api.geolims.com/invoice_service/saveInvoicePusat', body, { headers: header })
      .then(async response => {
          this.$refs.refInvoiceListTable.refresh()
          this.$refs.refInvoiceListTableConfirmed.refresh()
          this.$refs.refInvoiceListTableUnconfirmed.refresh()
          this.$refs.refInvoiceListTableEmpty.refresh()
        if (response.data.Status === 1) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Edit Invoice success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    dateFormat(value, format = 'MM/DD/YYYY hh:mm') {
        return moment(String(value)).format(format)
    },
    showMessages(invoiceID) {
      this.messageModal = true
      const userToken = getToken()
        const header = {
          'Content-Type': 'application/json',
          'X-Token-Access': `Bearer ${userToken}`,
        }
      this.invoiceMessageId = invoiceID
      const body = {
        invoiceID,
      }
    axios
      .post('https://api.geolims.com/invoice_service/getMessages', body, { headers: header })
      .then(response => {
        if (response.data.Status === 1) {
          console.log('Fetching Data')
      if (response.data.Payload) {
      messagesList.splice(0, messagesList.length)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Fetching Message success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        response.data.Payload.map(value => {
          console.log(value.invoiceMessage)
          messagesList.push(value)
          return value.invoiceMessage
        })
      }
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
    async sendMessage() {
      const userToken = getToken()
        const header = {
          'Content-Type': 'application/json',
          'X-Token-Access': `Bearer ${userToken}`,
        }
      const invoiceID = this.invoiceMessageId
      const Message = this.messageBox
      const body = {
        invoiceID,
        Message,
      }
    axios
      .post('https://api.geolims.com/invoice_service/inputMessages', body, { headers: header })
      .then(async response => {
        if (response.data.Status === 1) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sending Message success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.messageBox = ''
      const body2 = {
        invoiceID: this.invoiceMessageId,
      }
    axios
      .post('https://api.geolims.com/invoice_service/getMessages', body2, { headers: header })
      .then(response2 => {
        if (response2.data.Status === 1) {
          console.log('Fetching Data')
      if (response2.data.Payload) {
      messagesList.splice(0, messagesList.length)
        response2.data.Payload.map(value => {
          console.log(value.invoiceMessage)
          messagesList.push(value)
          return value.invoiceMessage
        })
      }
        }
        })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
    async deleteAttachment(attachmentID) {
      const userToken = getToken()
        const header = {
          'Content-Type': 'application/json',
          'X-Token-Access': `Bearer ${userToken}`,
        }
      const body = {
        InvoiceAttachmentID: attachmentID,
      }
    axios
      .post('https://api.geolims.com/invoice_service/deleteAttachment', body, { headers: header })
      .then(async response => {
        if (response.data.Status === 1) {
          this.modalFileAttachments = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Delete Attachment success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
    showContractAttachments(ContractCode) {
      const userToken = getToken()
        this.currentCode = ContractCode
        const header = {
          'Content-Type': 'multipart/form-dataitem',
          'X-Token-Access': `Bearer ${userToken}`,
        }
        try {
      axios
        .get(`https://api.geolims.com/invoice_service/getContractAttachmentsRef/${ContractCode}`, { headers: header })
        .then(response => {
          if (response.data.Status === 1) {
        fileAttachments.splice(0, fileAttachments.length)
        const agreementRef_ = response.data.Contract[1]
        const customer_ = response.data.Contract[2]
        const description_ = response.data.Contract[3]
        const issuedDate_ = response.data.Contract[4]
        const startDate__ = response.data.Contract[5]
        const endDate__ = response.data.Contract[6]
        this.agreementRef = agreementRef_
        this.customer = customer_
        this.description = description_
        this.issuedDate = issuedDate_
        this.startDate_ = startDate__
        this.endDate_ = endDate__
        if (response.data.Attachments) {
          response.data.Attachments.map(value => {
            fileAttachments.push(value)
            return value.s3_url
          })
          this.attachmentModal = true
        }
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Contract not found',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        } catch (error) {
        console.error('Error fetching contract:', error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Contract not found',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      },
    async uploadAttachment() {
      const userToken = getToken()
      const header = {
        'Content-Type': 'multipart/form-dataitem',
        'X-Token-Access': `Bearer ${userToken}`,
      }
      const formData = new FormData()
      formData.append('file', this.file[0], this.file[0].name)
      console.log(formData)
    axios
      .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_INVOICE}/upload-docs?invoiceid=${this.uploadInvoiceId}`, formData, { headers: header })
      .then(async response => {
        if (response.data.Status === 1) {
          this.modalUploadShow = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Upload Attachment success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    uploadFiles(invoiceID) {
        this.uploadInvoiceId = invoiceID
        this.modalUploadShow = true
      },
    downloadFile(folderName, fileName) {
const userToken = getToken()
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${userToken}`,
}
        const url = `https://api.geolims.com/asset_service/saved-assets/`
        const FileName = fileName.replace(url, '')
        const ObjectName = `${folderName}`
        const bodyFormData = new FormData()
        bodyFormData.append('ObjectName', ObjectName)
        bodyFormData.append('FileName', FileName)
      axios
        .post(`https://api.geolims.com/asset_service/get_asset`, bodyFormData, { headers })
        .then(response => {
        if (response.data.Status === 1) {
          window.open(response.data.Payload, '_blank')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Download Attachment success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
        },
    showAttachments(invoiceID) {
      this.modalFileAttachments = true
      const userToken = getToken()
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${userToken}`,
}
      const body = {
        invoiceMasterID: invoiceID,
      }
    axios
      .post('https://api.geolims.com/invoice_service/getAttachments', body, { headers })
      .then(response => {
        if (response.data.Status === 1) {
      fileAttachments.splice(0, fileAttachments.length)
      if (response.data.Payload) {
        response.data.Payload.map(value => {
          fileAttachments.push(value)
          return value.InvoiceAttachment
        })
      }
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
    downloadInvoice(id) {
      const url = process.env.VUE_APP_PDF_SERVICE_URL
      const header = {
  'auth-username': process.env.VUE_APP_PDF_SERVICE_USERNAME,
  'auth-password': process.env.VUE_APP_PDF_SERVICE_PASSWORD,
}
      const body = {
        id,
      }
    axios
      .post(url, body, { headers: header })
      .then(response => {
        window.open(response.data.data)
})
    },
    exportData(type, csvExportRef) {
      const userToken = getToken()
      const UserRole = getUserData().UserRoleSystem
      let userKodeUnitKerja = UserRole !== 'super-admin' && UserRole !== 'admin' && UserRole !== 'finance' ? getUserData().KodeUnitKerja : ''
      let dateFilterStart = ''
      let dateFilterEnd = ''
      if (type === 'all') {
        if (this.branchFilterAll !== "") {
          userKodeUnitKerja = this.branchFilterAll
        }
        if (this.startDateFilter !== "") {
          dateFilterStart = this.startDateFilter
        }
        if (this.endDateFilter !== "") {
          dateFilterEnd = this.endDateFilter
        }
      } else if (type === 'confirmed') {
        if (this.branchFilterConfirmed !== "") {
          userKodeUnitKerja = this.branchFilterConfirmed
        }
        if (this.startDateFilterConfirmed !== "") {
          dateFilterStart = this.startDateFilterConfirmed
        }
        if (this.endDateFilterConfirmed !== "") {
          dateFilterEnd = this.endDateFilterConfirmed
        }
      } else if (type === 'unconfirmed') {
        if (this.branchFilterUnconfirmed !== "") {
          userKodeUnitKerja = this.branchFilterUnconfirmed
        }
        if (this.startDateFilterUnconfirmed !== "") {
          dateFilterStart = this.startDateFilterUnconfirmed
        }
        if (this.endDateFilterUnconfirmed !== "") {
          dateFilterEnd = this.endDateFilterUnconfirmed
        }
      } else if (type === 'empty') {
        if (this.branchFilterEmpty !== "") {
          userKodeUnitKerja = this.branchFilterEmpty
        }
        if (this.startDateFilterUnconfirmed !== "") {
          dateFilterStart = this.startDateFilterUnconfirmed
        }
        if (this.endDateFilterEmpty !== "") {
          dateFilterEnd = this.endDateFilterEmpty
        }
      }
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_INVOICE}/getinternal?unit_kerja=${userKodeUnitKerja}&limit=9999&offset=0&type=${type}&date_start=${dateFilterStart}&date_end=${dateFilterEnd}`
      this.isLoadingExport = true
      axios.get(url, {
          headers,
      }).then(response => {
        // Populate invoicesAll with the API response data
      this.invoicesAll = response.data.Payload
    this.invoicesAll = this.invoicesAll.map(invoice => ({
      ...invoice,
      InvoiceNumber: `${invoice.InvoiceNumber}   `, // Wrap in double quotes
    }))
      // Optional success message
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data Fetched Successfully",
          icon: "CheckIcon",
          variant: "success",
        },
      })
      this.$nextTick(() => {
        if (this.$refs[csvExportRef]) {
          this.$refs[csvExportRef].$el.click()
        } else {
          console.error("CSV Export reference not found")
        }
      })
      }).finally(() => {
        this.isLoadingExport = false
      })
    },
    async downloadAttachment(fileName) {
const userToken = getToken()
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${userToken}`,
}
        const url = `https://api.geolims.com/asset_service/saved-assets/`
        const folderName = `InvoiceAttachment/`
        const FileName = fileName.replace(url, '')
        const ObjectName = `${folderName}${FileName}`
        const bodyFormData = new FormData()
        bodyFormData.append('ObjectName', ObjectName)
        bodyFormData.append('FileName', FileName)
      axios
        .post(`https://api.geolims.com/asset_service/get_asset`, bodyFormData, { headers })
        .then(response => {
        if (response.data.Status === 1) {
          window.open(response.data.Payload, '_blank')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Download Attachment success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    showDetail(item) {
      localStorage.setItem("invdet", item)
      this.$router.push({ name: "apps-invoice-detail" })
    },
    hasPermission(permission) {
      const permissions = "woi"
      return permissions.includes(permission) ? true : false
    },
    cutString(string) {
      const lengthOf = string.length
      return lengthOf > 30 ? `${string.substring(0, 30)} ...` : string
    },
    showDocuments(id) {
      const userToken = getToken()
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.materialRequestId = id
      this.modalUpload = true
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}mr/${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            console.log("Fetching Data")
            if (response.data.data) {
              this.documentsList.splice(0, this.documentsList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Documents success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response.data.data.map(value => {
                this.documentsList.push(value)
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Documents failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    handleFilesUpload(e) {
          this.file = e.target.files || e.dataTransfer.files
      },
    sendDocument() {
      const userToken = getToken()
      const header = {
        'Content-Type': 'multipart/form-dataitem',
        Authorization: `Bearer ${userToken}`,
      }
      const formData = new FormData()
      if (typeof this.file[0] !== 'undefined') {
      formData.append('file', this.file[0], this.file[0].name)
      formData.append('document_material_request_id', this.materialRequestId)
      formData.append('time', moment())
    axios
      .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_DOCUMENT}`, formData, { headers: header })
      .then(async response => {
        if (response.data.success === true) {
            this.$refs.refInvoiceListTable.refresh()
            this.$refs.refInvoiceListTableConfirmed.refresh()
            this.$refs.refInvoiceListTableUnconfirmed.refresh()
            this.$refs.uploadFile.reset()
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}mr/${this.materialRequestId}`,
          { headers: header },
        )
        .then(response2 => {
          if (response2.data.success === true) {
            if (response2.data.data) {
              this.documentsList.splice(0, this.documentsList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Documents success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response2.data.data.map(value => {
                this.documentsList.push(value)
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Documents failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Purchase Request Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please Upload The Document!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px
}
.btable {
  min-height: 400px; /* Set your desired height */
  overflow-y: auto;
}
.b-calendar {
  z-index: 1050!important; /* Adjust the z-index to be higher than the modal */
}
.hidden {
  display: none;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  z-index: 9999;
}

.loading-gif {
  width: 50px; /* Adjust the size of the GIF */
  height: 50px;
}
.pad-down-2{
  padding-bottom: 20px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss"
</style>